import React, { Component } from 'react'
import {  notify } from '../../libraries/notifications';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'


import ChallengesWrapper from './ChallengesWrapper';

import challengesActions from "../../context/challenges/actions";
import benefitsActions from "../../context/benefits/actions";
import Loader from '../../components/commons/Loader';
import { withRouter } from 'react-router-dom';

import categoriesActions from '../../context/categories/actions';
import actionsActions from '../../context/actions/actions';
import { differenceInDays, differenceInHours, format, parseISO } from 'date-fns';

import { history } from '../../routes';
import config from '../../config';
import Icon from '../../libraries/icons';
import medal from '../../assets/img/medal.png';
import { sortArrayByAttribute, strToDateWithoutTZ } from '../../libraries/utils';

import MultiLineTextDisplay from '../../components/commons/MultiLineTextDisplay';

import puntos_shary from '../../assets/img/puntos-shary.jpg';
import puntos_shary_en from '../../assets/img/shary-points.jpg';
import creditos from '../../assets/img/creditos.jpg';
import creditos_en from '../../assets/img/credits.jpg';
import { useTranslation } from 'react-i18next';


class Info extends Component {
  
  constructor(props) {
    super(props);
    this.t = this.props.t;    
    this.state = {
      challenge: {},
      challenges_awards : [],

    }

  }

  async componentDidMount() {
    //await this.loadChallenge();
    await this.loadAwards()
    await this.loadActivities()
  }

  loadActivities = async () => {
    const { id } = this.props.match.params
    
    await this.props.onGetActivities(id);
    let activities = [...this.props.challenges_activities]
    if(activities){
      activities.forEach(act => {
        let sub_type = act.sub_type
        let acts = activities.map(act => act.activity)

        if(sub_type === "category"){
          this.getCategories({id:acts})
        }

        if(sub_type === "n_actions"){
          this.getActions({id:acts})
        }

        if(sub_type === "borrow"){
          this.setState({activity_borrow: true})
        }

        if(sub_type === "lend"){
          this.setState({activity_lend: true})
        }
        
      })
    }
    this.setState({activities: this.props.challenges_activities})
  } 

  getCategories = async (params) => {
    if(!!params.id.find(id => id ==="ALL_CATEGORIES")) {
      // params.id = undefined
      return
    }

    await this.props.onGetCategories(params);//{...params, type: "actions"}
    const { categories } = this.props;
    if (categories.error) {
      notify(this.t(categories.error.message));
    } else {
      this.setState({categories: categories.items})
    }
  }

  getActions = async (params) => {
    await this.props.onGetActions(params);
    const { actions } = this.props;
    if (actions.error) {
      notify(this.t(actions.error.message));
    } else {
      this.setState({actions: actions.items})
    }
  }

  loadAwards = async () => {
    const { id } = this.props.match.params
    await this.props.getAwards(id);
    const benefitIds = this.props.challenges_awards.map(a => a.benefit)

    if(benefitIds.length){
      await this.props.getAllBenefits({id: benefitIds});
      let challenges_awards = this.props.challenges_awards.map(a => {
        if(a.benefit === "coins" || a.benefit === "credits"){
          return {
            name: `${a.quantity} ${a.benefit}`,
            order_number: a.order_number, 
            json_data: {
              picture: "", //https://sharyco-public-files.s3.amazonaws.com/undefined_img_logo_sharyco.png
              benefit: a.benefit
            }
          }
        }
        let benefit = this.props.benefits.items.find(benefit => benefit.id === a.benefit)
        return {
          ...benefit,
          order_number: a.order_number, 
        }
      })

      // challenges_awards = [...challenges_awards.sort((a,b) => { return a.order_number - b.order_number })]

      challenges_awards = sortArrayByAttribute(challenges_awards, 'order_number')
      this.setState({ challenges_awards   });
    }
  }


  
  

  render() {
    const { challenges_awards, activities, actions, categories } = this.state
    

    const { item: challenge } = this.props.challenge
    let ganadores
    if( challenge && (challenge.winner === "umbral")) ganadores = `Ganan los que sumen más de ${challenge.winner_number} ${ (challenge.condition_type === "actions") ? (challenge.winner_number > 1 ? 'acciones' : 'accion') : (challenge.winner_number > 1 ? 'puntos' : 'punto')}`

    if(challenge &&( challenge.winner === "first_n")) ganadores = `Ganan los primeros ${challenge.winner_number} del ranking`    


    const allCategories = !!activities?.find(act => act.activity === "ALL")
    const haveCategories = !!categories?.length
    
    return (
      <ChallengesWrapper>
        {
          !challenge && <Loader/> 
        }
        {
          challenge && (
            <>
              <div className='pb-5'>
               <MultiLineTextDisplay className="font-normal" text={challenge.details}/>
              </div>
              <div className='pb-5'>
               {challenge.condition_type === "actions" && "En este desafío se puntúa con la cantidad de acciones realizadas (1 acción = 1 punto)"}
               {challenge.condition_type === "points" && "En este desafío se puntúa con la cantidad de puntos de impacto de las acciones realizadas"}
              </div>
              <div className='grid grid-cols-2 gap-2 w-full'>
                <div>
                  <h4>Inicia</h4>
                  <p className="text-neutral">{ strToDateWithoutTZ(challenge.start_date).toLocaleDateString("es-AR", { year: 'numeric', month: 'long', day: 'numeric' })} (a las 00:00hs)</p>
                </div>
                <div>
                  <h4>Finaliza <span className='text-xs'>({this.t("timeRemains", { date: strToDateWithoutTZ(challenge.end_date) })})</span></h4>
                  {/* en {this.daysRemainingShort(challenge?.end_date)} */}
                  <p className="text-neutral">{strToDateWithoutTZ(challenge.end_date).toLocaleDateString("es-AR", { year: 'numeric', month: 'long', day: 'numeric' })} (a las 00:00hs)</p>
                </div>
              </div>


              <h2 className='mt-5'>Tareas</h2>
              
                {activities && !activities.length && <p>Este desafío no tiene premios.</p>}
                {
                  activities && activities.length &&
                  <>
                    {
                      allCategories &&
                      <>
                        <p className='text-neutral'>Realizar todas las acciones en estas categorías:</p>
                        <div className='h-full card white p-3 gap-3 shadow-lg hover:cursor-pointer pb-4 mt-2 mb-3' onClick={() => history.push({pathname: config.ROUTES.HOME_OPTIONS.ACTIONS, state: {}})}>
                          <h3 className='text-center'>Todas las categorías</h3>
                          <div className='flex justify-center gap-2'>
                            <Icon name="alimentos" size="24"/>
                            <Icon name="compras" size="24"/>
                            <Icon name="energia" size="24"/>
                            <Icon name="familia" size="24"/>
                            <Icon name="hogar" size="24"/>
                            <Icon name="oficina" size="24"/>
                            <Icon name="residuos" size="24"/>
                            <Icon name="salud" size="24"/>
                            <Icon name="transporte" size="24"/>
                          </div>
                        </div>
                      </>
                    }
                    {
                      haveCategories && 
                      <>
                        <p className='text-neutral'>Realizar todas las acciones en estas categorías:</p>
                        <div className="scrolling-wrapper pt-2 pb-3 gap-4 mb-5 mt-2">
                        {categories.map((category, i) => {
                          return (
                              <ChallengeCategoryCard category={category} key={`category${i}`} challenge_id={challenge?.id}/>
                          )
                        })}
                      </div>
                      </>
                    }
                    {actions && actions.length !== 0 && 
                      <>
                        <p className='text-neutral'>{(allCategories || haveCategories) ? "También podes realizar las siguientes acciones:": "Podes realizar las siguientes acciones:"}</p>
                        <div className='flex flex-col gap-2'>
                          {
                            actions.map((action, i) => (
                              <ChallengeActionCard action={action} key={`action${i}`} challenge_id={challenge?.id}/>
                            ))
                          }
                        </div>
                      </>
                    }
                  </>
                }

              <h2>Premios</h2>
              <p className="text-neutral">{ganadores}</p>
              
              {challenges_awards && challenges_awards.length === 0 && <p>Este desafío no tiene premios.</p>}
              {
                challenges_awards && challenges_awards.map((award, i) =>  (
                  <div className="my-4" key={i}>
                    <AwardCard award={award} winner_number={i}/>
                  </div>
                ))
              }
            </>
          )
        }

      </ChallengesWrapper>

    )
  }
}

const AwardCard = ({award, winner_number}) => {
  const {i18n}  = useTranslation();
  
  const bg = (award.json_data?.benefit === "coins" || award.json_data?.benefit === "credits" ) ? "bg-primary text-primary" : "bg-white"
  let icon
  let name = award.name

  if(award.json_data?.benefit === 'coins' || award.json_data?.benefit === 'points') {
    name = `${award.name.split(" ")[0]} Puntos Shary`
    icon = i18n.language === "es" ? puntos_shary : puntos_shary_en
  }
  if(award.json_data?.benefit === 'credits') {
    name = `${award.name.split(" ")[0]} Créditos`
    icon = i18n.language === "es" ? creditos : creditos_en
  }

  return (
  <div className="card overflow-hidden bg-success shadow-[0_3px_5px_3px_rgba(0,0,0,0.2)] bg-opacity-50 min-h flex flex-row" >
    <figure className={`rounded-none h-24 w-28 ${bg}`}>
      <img src={award.json_data?.picture || icon} alt="award" className="object-cover h-full w-full"/>
    </figure>
    <div className="p-3 flex-1 flex flex-col justify-center">
      {/* {typeof winner_number === "number" && <h5 className="text-white">{winner_number}</h5>} */}
      <h5 className="font-bold">{name}</h5>
    </div>
    <div className='pl-3 '>
      <div className='relative'>
      <img src={medal} className='w-12' alt="medal"/>
      <p className='absolute bottom-1 right-0 left-0 text-center text-2xl font-bold'>{winner_number === "todos" ? "": winner_number + 1}</p>
      </div>
    </div>   
  </div>
  )
}


const ChallengeActionCard = ({action, challenge_id}) => {
  return (
  <div className="card overflow-hidden bg-white flex flex-row" onClick={() => history.push({pathname: config.ROUTES.ACTIONS.DETAIL.replace(':id', action.id ), state: {fromPath: `/challenges/${challenge_id}/info`}} )}>
  <figure className="rounded-none max-h-24 w-28 bg-white"><img src={action.json_data?.img} alt="" className="object-cover h-full"/></figure>
    <div className="p-3 flex-1">
      <h5 className="">{action.name}</h5>
    </div>
  </div>
  )
}

const ChallengeCategoryCard = ({category, challenge_id}) => {
  const showCategoryIcon = category.image
  return (
    <div className="card white p-3 flex w-32 gap-2" onClick={() => history.push({pathname: config.ROUTES.HOME_OPTIONS.ACTIONS, state: {from: `/challenges/${challenge_id}/info`, filters: {type:category.id}}} )} >
      <figure>
        { showCategoryIcon
          ? <Icon name={showCategoryIcon} size="42px" /> 
          : <img
            src="https://sharyco-public-files.s3.amazonaws.com/undefined_img_logo_sharyco.png"
            alt="award"
            className="object-cover max-h-20 w-full"
            />
        }
      </figure>
      <div className="flex-1 text-center">
        <h5 className="">{category.name}</h5>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    // auth: state.users.auth,
    // challenges: state.challenges.list,
    challenge: state.challenges.current,
    challenges_awards: state.challenges.current?.awards,
    challenges_activities: state.challenges.current?.activities,
    benefits: state.benefits.list,
    benefits_item: state.benefits.item,
    actions: state.actions.list,
    categories: state.categories.list,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAwards: (id) => dispatch(challengesActions.getAwards(id)),
    getBenefits: (id) => dispatch(benefitsActions.get(id)),
    getAllBenefits: (params) => dispatch(benefitsActions.getAll(params)),
    onGetChallenge: (params) => dispatch(challengesActions.get(params)),
    onGetActivities: (id) => dispatch(challengesActions.getActivities(id)),
    // onGetAllCategories: (params) => dispatch(actionCategories.getAll(params)),
    onGetCategories: params => dispatch(categoriesActions.getAll(params)),
    onGetActions: params => dispatch(actionsActions.getAll(params)),
  }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Info)))